<template>
  <div class="template field-report-template">
    <field-report-template-menu></field-report-template-menu>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FieldReportTemplateMenu from '@/components/modules/familyWebsite/templates/field_report/FieldReportTemplateMenu';

export default {
  components: {FieldReportTemplateMenu},
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables-field';
@import '../../styles/base-styles-field';

.field-report-template-menu {
  z-index: 10;
}

.field-report-template .content::v-deep {
  @import '../../styles/glossary';
  padding-top: 80px;

  .home_bg_img {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: fixed;
    left: 0;
    top: 0;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .home_bg_gradient_left {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3));
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    .home_bg_gradient_right {
      background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }

  .home_content_wrapper {
    z-index: 2;
    position: relative;
    color: #aaa;
  }

  h1 {
    color: #fff;
    text-shadow: 2px 2px 5px #000;
    font-size: 42px;
    span {
      display: block;
      font-size: 0.7em;
      color: #ccc;
    }
  }

  .field_report_log {
    .log_entry {
      display: flex;
      clear: both;

      .log_meta {
        float: left;
        margin-right: 20px;
        width: 125px;
        margin-bottom: 15px;

        .log_time {
          display: block;
          opacity: 0.7;
        }

        .log_place {
          display: block;
          font-size: 0.9em;
          opacity: 0.5;
        }
      }

      .log_note {
        float: left;
      }

      &::after {
        content: '';
        clear: both;
        display: table;
      }
    }
  }

  .bios_container {
    margin-bottom: 50px;
    &::after {
      content: '';
      clear: both;
      display: table;
    }

    .bios {
      float: left;
      margin-right: 10px;

      &::after {
        content: '';
        clear: both;
        display: table;
      }

      h4 {
        clear: both;
        margin-top: 25px;
        font-weight: normal;
        text-transform: uppercase;
        line-height: 1.3em;
        margin-bottom: 0;
        padding-bottom: 10px;
        font-family: var(--report-accent-font);
        color: #fff;
        opacity: 0.25;
        display: block;
        text-shadow: 2px 2px 2px #000;
        transition: all 0.5s ease;
        font-size: 0.9em;
      }

      .bio {
        height: 70px;
        float: left;
        clear: none;
        width: 320px;

        .circle_crop {
          width: 70px;
          height: 70px;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          float: left;
          margin-right: 15px;

          img {
            display: inline;
            margin: 0 auto;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        em {
          font-weight: normal;
          font-style: normal;
          display: block;
        }

        span {
          display: block;
          font-size: 0.9em;
          opacity: 0.5;
        }

        &.researcher {
          .circle_crop {
          }
        }

        &.commissioner {
          em {
            top: 16px;
            position: relative;
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      margin-bottom: 0;
      .bios {
        float: none;
        .bio {
          width: auto;
          float: none;
          margin-bottom: 40px;
        }
      }
    }
  }

  .highlights {
    margin-top: 0px;

    .highlights_container {
      display: flex;
      flex-wrap: wrap;
      a {
        display: block;
        margin-right: 20px;
        transition: all 0.5s ease;
        margin-bottom: 30px;

        h4 {
          font-weight: normal;
          text-transform: uppercase;
          line-height: 1.3em;
          margin-bottom: 0;
          padding-bottom: 8px;
          font-family: var(--report-accent-font);
          color: #fff;
          opacity: 0.25;
          display: block;
          text-shadow: 2px 2px 2px #000;
          transition: all 0.5s ease;
          font-size: 0.9em;
        }

        img {
          object-fit: cover;
          width: 230px;
          height: 120px;
          margin-top: 10px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          transition: all 0.5s ease;
          border-radius: 4px;
        }

        &:hover {
          text-decoration: none;

          h4 {
            color: var(--report-link-color);
            opacity: 1;
          }

          img {
            border: 1px solid var(--report-link-color);
          }
        }

        &.key_contacts {
          img {
            width: 50px;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &::after {
          content: '';
          clear: both;
          display: table;
        }
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>
